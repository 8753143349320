.dashboard .card-header {
    background: none;
    border-bottom: none;
}

.dashboard .grey-on-hover:hover svg.action-icon {
    color: gray;
}

.dashboard .action-card {
    height: 182px;
}

.dashboard .menu-sidebar {
    width: 250px;
}

@media (max-width: 767px) {
    .dashboard .menu-sidebar {
        width: 75px;
    }
}

.dashboard .menu-card {
    top: 25px;
    max-width: 250px;
}

.dashboard .menu-sidebar svg {
    width: 25px;
}
